body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  user-select:none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img{
  -webkit-user-drag: none;
}
.ck-editor__editable_inline{
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}

.ck.ck-dropdown .ck-dropdown__arrow {
	pointer-events: none;
	z-index: 0!important;
}
p{
  margin-top: auto !important
}
.css-mozcj9{
  padding:0!important;
  min-height:77px!important;
}
.navbar-nav {
  padding-left: 25px!important;
}
button:focus{
  outline: none !important;
  outline: none !important;
}