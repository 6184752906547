.banner{
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 0;
    background-image: url("../../public/images/signin//login_bg.png");
    background-repeat:repeat;
    background-size:cover;
  }
  .MuiTabPanel-root{
    display: none;
  }
  .TitleImg{
  cursor: pointer;
  }
  .Button{
    cursor: pointer;
  }

