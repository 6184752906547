.scroll {
  width: 100%;
  height  : 100%;
  overflow: auto;
}

.scrollbar {
height:100vh;
}

.scroll-1::-webkit-scrollbar {
/*滚动条整体样式*/
width : 8px;  /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll-1::-webkit-scrollbar-thumb {
/*滚动条里面小方块*/
border-radius: 10px;
box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
background   : #535353;
}

.scroll-1::-webkit-scrollbar-track {
/*滚动条里面轨道*/
box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
border-radius: 10px;
background   : #ededed;
}